import {FormattedMessage} from "react-intl";

export const BASE_URL = "https://appbackend.cashgoldrecords.group/";
// export const BASE_URL = "http://192.168.0.103:8000/";

export const DATE_FORMAT_SERVER = "yyyy-MM-dd";
export const DATE_FORMAT_DISPLAY = "dd/MM/yyyy";
export const ACCESS_TOKEN = "music_management_access";
export const SUPPORTED_IMAGES =["image/png","image/jpeg"]
export const SUPPORTED_TRACKS =["ogg","mp3", "wav", "jpg"]
export const POPUP_SMALL = "small";
export const POPUP_MEDIUM = "medium";
export const POPUP_LARGE = "large";

export const THEME_DARK = "dark";
export const THEME_LIGHT = "light";
export const THEME_ACCESS = "m_theme";
export const PRIMARY_COLOR = "#570DF8"
export const BORDER_DARK_COLOR = "#434955"
export const BORDER_LIGHT_COLOR = "#d2d4d7"

export const ADMIN_ROLE = "super-admin";
export const USER_ROLE = "end-user";
export const LABEL_ROLE = "label-user";

export const ROLES_ARR = [
    {
        value: USER_ROLE,
        label: <FormattedMessage id="normal_user" defaultMessage={"Normal User"} />
    },
    {
        value: LABEL_ROLE,
        label: <FormattedMessage id="label_user" defaultMessage={"Label User"} />
    },
]


export const ENGLISH_LANG = {
    lang: "en",
    label: "English",
    ltr: true,
}
export const INDONESIAN_LANG = {
    lang: "id",
    label: "Indonesian",
    ltr: true,
}
export const LANG_OPTIONS = [
    ENGLISH_LANG,
    INDONESIAN_LANG
]

// custom styles
export const tableStyles = {
    rows: {
        style: {
        minHeight: '60px', // override the row height
        borderBottom: "0.8px solid #ccc",
        }
    },
    headCells:{
        style:{
            color:"#000",
            fontSize:"14px",
            // backgroundColor: "#40e0d0",
            backgroundColor: "#fff",
            fontWeight: "600"
        }
    },
    cells: {
        style: {
           color: "#000",
           fontWeight: "500",
           minWidth:'120px'
        },
    },
};
// custom styles
// custom styles
export const tableStylesSmoll = {
    rows: {
        style: {
        // minHeight: '60px', // override the row height
        borderBottom: "0.8px solid #ccc",
        }
    },
    headCells:{
        style:{
            color:"#000",
            fontSize:"12px",
            // backgroundColor: "#40e0d0",
            backgroundColor: "#fff",
            fontWeight: "700"
        }
    },
    cells: {
        style: {
           color: "#000",
           fontSize:"12px",
           fontWeight: "500",
           minWidth:'120px'
        },
    },
};
// custom styles

export const initialPopupState = {
    component: null,
    visible: false,
    size: "small",
    heading: "",
    contained: false,
    hideDefaultClose: false
}

export const LISTING_LIMIT = 10;

export const PAGINATION_OPTIONS = {
    rowsPerPageText: <FormattedMessage id="rows.page" defaultMessage="Rows per page" />,
    rangeSeparatorText: '-',
    selectAllRowsItem: true,
    selectAllRowsItemText: <FormattedMessage id="select.all" defaultMessage={"Select All"}/>,
};
