import React from 'react'
import {BiLoaderAlt} from "react-icons/bi";
import {motion} from "framer-motion";

export const Loading = () => {
    return (
        <div className="flex justify-center items-center my-12">
            <span className="text-5xl text-green-secondary">
                <BiLoaderAlt className="animate-spin"/>
            </span>
        </div>
    )
}

export default Loading

export const FullScreenLoading = () => {
    return (
        <div className="loader-screen left-0 top-0 fixed h-screen w-screen bg-base-100 flex items-center justify-center" style={{zIndex: 10000}}>
            <h2 className="animate-spin text-7xl text-primary"><BiLoaderAlt /></h2>
        </div>
    )
} 

export const LogoLoading = () => {
    return (
        <div className="loader-screen left-0 top-0 fixed h-screen w-screen bg-base-100 flex flex-col items-center justify-center" style={{zIndex: 10000}}>
            <div
            className="relative flex flex-col items-center justify-center">
                <h2 
                    className="animate-spin text-7xl text-primary"
                >
                    <BiLoaderAlt />
                </h2>
            </div>
        </div>
    )
}
