import React, { Suspense, useState } from 'react'
import { useSelector } from 'react-redux';
import { FullScreenLoading } from "../components/Loading";
import Sidebar from "../components/Sidebar";
import { ADMIN_ROLE, LABEL_ROLE, USER_ROLE } from '../constants';
import {adminLinks} from "./adminLinks";
import {userLinks} from "./userLinks";

import AdminRoutes from './AdminRoutes';
import UserRoutes from './UserRoutes';

const AuthenticatedRoutes = () => {
    const [aside, setAside] = useState(false);
    const user = useSelector(state => state.user);
    return (
        <Suspense fallback={<FullScreenLoading/>}>
            <Sidebar aside={aside} setAside={setAside} links={user.role === ADMIN_ROLE ? adminLinks : user.links === USER_ROLE? userLinks: userLinks } />
            <div className="ltr:lg:pl-adjust-container rtl:lg:pr-adjust-container h-full flex flex-col">
                {
                    user.role === ADMIN_ROLE ?
                    <AdminRoutes/>
                    : user.role === USER_ROLE || user.role === LABEL_ROLE ? 
                    <UserRoutes/>
                    :null
                }
            </div>
        </Suspense>
    )
}

export default AuthenticatedRoutes