import { store } from "react-notifications-component";
import * as yup from "yup";
import { ACCESS_TOKEN, initialPopupState, PRIMARY_COLOR, THEME_ACCESS, THEME_DARK, THEME_LIGHT } from "./constants";
import {BsCheckCircleFill} from 'react-icons/bs'
import {MdCancel} from 'react-icons/md'
import CurrencyFormat from "react-currency-format";

export const getDefaultTheme = () => {
    let theme = localStorage.getItem(THEME_ACCESS);
    if (theme) {
        return theme === THEME_DARK ? THEME_DARK : theme === THEME_LIGHT ? THEME_LIGHT : THEME_LIGHT;
    } else {
        localStorage.setItem(THEME_ACCESS, THEME_LIGHT)
        return THEME_LIGHT;
    }
}
export const setDefaultTheme = () => {
        localStorage.setItem(THEME_ACCESS, THEME_LIGHT)
}

export const getAuthUser = () => {
    let token = localStorage.getItem(ACCESS_TOKEN);
    if (token) {
        return JSON.parse(token).user;
    } else {
        return false;
    }
}

export const getToken = () => {
    let token = localStorage.getItem(ACCESS_TOKEN);
    if (token) {
        return JSON.parse(token).access_token;
    } else {
        return false;
    }
}
export const getRefresh = () => {
    let token = localStorage.getItem(ACCESS_TOKEN);
    if (token) {
        return JSON.parse(token).refresh_token;
    } else {
        return false;
    }
}
export const checkRefresh = () => {
    let token = localStorage.getItem(ACCESS_TOKEN);
    if (token) {
        let parsed  = JSON.parse(token)
        if(parsed.refresh_token){
            return true
        } else{
            return false
        }
    } else {
        return false;
    }
}

export const setToken = (access_token) => {
    localStorage.setItem(ACCESS_TOKEN, JSON.stringify(access_token));
}

export const removeToken = () => {
    localStorage.removeItem(ACCESS_TOKEN);
}

export const success_alert = (message, title) => {
    let new_msg = message;
    store.addNotification({
        title: title ? title : "",
        message: <div className="flex items-center gap-2"><BsCheckCircleFill className="text-lg text-green-500"/>{new_msg}</div>,
        className: "noti",
        type: "success",
        insert: "top",
        container: "top-center",
        animationIn: ["animate__animated", "animate__fadeInDown"],
        animationOut: ["animate__animated", "animate__fadeOutUp"],
        dismiss: {
            duration: 3000,
            onScreen: true,
            showIcon: true
        }
    })
}

export const error_alert = (message, title) => {
    let new_msg = JSON.stringify(message);
    if ((new_msg[0] === '"' || new_msg[0] === "'") && (new_msg[new_msg.length - 1] === '"' || new_msg[new_msg.length - 1] === "'")) {
        new_msg = new_msg.substr(1, new_msg.length - 2);
    } 
    store.addNotification({
        title: title ? title : "",
        message:<div className="flex items-center gap-2"><MdCancel className="text-lg text-red-500"/>{new_msg}</div>,
        className: "noti",
        type: "danger",
        insert: "top",
        container: "top-center",
        animationIn: ["animate__animated", "animate__fadeInDown"],
        animationOut: ["animate__animated", "animate__fadeOutUp"],
        dismiss: {
            duration: 3000,
            onScreen: true,
            showIcon: true
        }
    })
}

export const warning_alert = (message, title) => {
    store.addNotification({
        title: title ? title : "",
        message: message,
        type: "warning",
        insert: "bottom",
        container: "bottom-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
            duration: 7000,
            onScreen: true,
            showIcon: true
        }
    })
}


export function stringNotEmpty() {
    return yup.mixed().test({
        name: 'stringNotEmpty',
        exclusive: false,
        message: "Required",
        test: function (value) {
            if (value !== undefined && value !== false) {
                return value.trim() !== "";
            } else if (value === undefined) {
                return false
            }
        },
    });
}


export const selectStyles = (error, theme) => (
    {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: theme === THEME_DARK ? "#2a303c" : "#fff",
            borderRadius: "0.375rem",
            border: state.isDisabled ? "0px solid #e4e4e4" : state.isFocused ? `1px solid ${PRIMARY_COLOR}` :  state.isSelected ? "1px solid #ef4444" : state.hasValue ? "1px solid #40e0d0": error ? "1px solid #ef4444": "1px solid #cbd5e1",
            boxShadow: state.isFocused ? "0px 0px 6px #40e0d0" : "none",
            "&:hover": {
                border: "2px solid #40e0d0",
                boxShadow: "0px 0px 6px #40e0d0"
            },
            fontSize: "1rem",
            "@media (max-width:1024px)": {
                fontSize: "0.875rem"
            }
            
        }),
        option: (provided, state) => {
            return ({
                ...provided,
                background: state.isSelected ? "#40e0d0" : "white",
                color: state.isSelected ? "#000" : "#500",
                "&:hover": {
                    "background": "#40e0d0",
                    color: "#500"
                },
                fontSize: "1rem",
                "@media (max-width:1024px)": {
                    fontSize: "0.875rem"
                }
            })
        },
        
        singleValue: (provided, state) => {
            const opacity = state.isDisabled ? 0.5 : 1;
            const transition = 'opacity 300ms';
    
            return { ...provided, opacity, transition };
        }
    }
)
export const selectStylesFilter = error => (
    {
        
        control: (provided, state) => ({
            ...provided,
            height: "12px",
            fontSize: "12px",
            backgroundColor: state.isDisabled ? "#fff" : "#fff",
            borderRadius: "0.375rem",
            border: state.isDisabled ? "2px solid #e4e4e4" : state.isFocused ? "2px solid #40e0d0" :  state.isSelected ? "2px solid #ef4444" : state.hasValue ? "2px solid #40e0d0": error ? "2px solid #ef4444": "2px solid #cbd5e1",
            boxShadow: state.isFocused ? "0px 0px 6px #40e0d0" : "none",
            "&:hover": {
                border: "2px solid #40e0d0",
                boxShadow: "0px 0px 6px #40e0d0"
            },
            
        }),
        
        option: (provided, state) => {
            return ({
                ...provided,
                background: state.isSelected ? "#40e0d0" : "white",
                color: state.isSelected ? "#000" : "#500",
                fontSize: "12px",
                "&:hover": {
                    "background": "#40e0d0",
                    color: "#500"
                },
            })
        },
        
        singleValue: (provided, state) => {
            const opacity = state.isDisabled ? 0.5 : 1;
            const transition = 'opacity 300ms';
    
            return { ...provided, opacity, transition, fontSize: "12px" };
        }
    }
)


export const hidePopup = (setState) => {
    setState(initialPopupState)
}


export const customStylesForDarkMode = {
    rows: {
        style: {
        minHeight: '60px', // override the row height
        backgroundColor: "#2A303C",
        outlineWidth: "0px !important",
        "&:hover": {
            // backgroundColor: "#151521 !important",
            // borderBottom: "1px solid #14141f !important",
            // outlineWidth: "0px !important",
        },
        "&:nth-child(odd)": {
            backgroundColor: "#2A303C",
            borderTop: "0px solid #14141f",
            borderBottom: "0px solid #14141f",
            outlineWidth: "0px !important",
        },
        "&:nth-child(even)": {
            borderTop: "0px solid #14141f",
            borderBottom: "0px solid #14141f",
            outlineWidth: "0px !important",
        },
        borderTop: "0px solid #14141f !important",
        outlineWidth: "0px !important",
        borderBottom: "1px solid #14141f !important",
    }
    },
    headRow: {
        style:{
            borderBottom: "1px solid #14141f",
            color:"#a1a5b7",
            fontSize:"14px",
            backgroundColor: "#2A303C",
            fontWeight: "400"
        }
    },
    headCells:{
        style:{
            borderBottom: "0px solid #14141f",
            color:"rgba(255,255,255, 0.6)",
            fontSize:"14px",
            backgroundColor: "#2A303C",
            fontWeight: "400"
        }
    },
    cells: {
        style: {
           color:"rgba(255,255,255, 0.8)",
           backgroundColor: "transparent !important",
           fontWeight: "500",
           minWidth:'120px',
        },
    },
    pagination: {
		style: {
            borderTop: "0px solid #14141f",
			color: 'white',
			fontSize: '13px',
			minHeight: '56px',
			backgroundColor: '#2A303C',
            borderBottomLeftRadius:'0',
            borderBottomRightRadius:'0px'
	},
    pageButtonsStyle: {
        borderTop: "1px solid #14141f",
        borderRadius: '0',
        height: '40px',
        width: '40px',
        padding: '8px',
        margin: 'px',
        cursor: 'pointer',
        transition: '0.4s',
        color: 'white',
        fill: 'white',
        backgroundColor: 'transparent',
        '&:disabled': {
            cursor: 'unset',
            color: 'white',
            fill: 'white',
        },
        '&:hover:not(:disabled)': {
            backgroundColor: 'white',
        },
        '&:focus': {
            outline: 'none',
            backgroundColor: 'white',
        },
    },
    }
};

export const customStylesForLightMode = {
    rows: {
        style: {
        minHeight: '60px', // override the row height
        backgroundColor: "#ffffff",
        }
    },
    headCells:{
        style:{
            color:"#a1a5b7",
            fontSize:"14px",
            // backgroundColor: "#40e0d0",
            // backgroundColor: "#ffffff",
            fontWeight: "400"
        }
    },
    cells: {
        style: {
           color: "#00000",
        //    backgroundColor: "#ffffff",
           fontWeight: "500",
           minWidth:'120px',
        //    padding:'20px'
        },
    },
    pagination: {
		style: {
			color: 'black',
			fontSize: '13px',
			minHeight: '56px',
			backgroundColor: '#FFFFFF',
         
		},
		pageButtonsStyle: {
			borderRadius: '50%',
			height: '40px',
			width: '40px',
			padding: '8px',
			margin: 'px',
			cursor: 'pointer',
			transition: '0.4s',
			color: 'black',
			fill: 'black',
			backgroundColor: 'transparent',
			'&:disabled': {
				cursor: 'unset',
				color: 'black',
				fill: 'black',
			},
			'&:hover:not(:disabled)': {
				backgroundColor: 'black',
			},
			'&:focus': {
				outline: 'none',
				backgroundColor: 'black',
			},
		},
	},

};

export const displayImage = (path) => {
    let base = process.env.NODE_ENV === "production" ? "" : process.env.REACT_APP_DEVELOPMENT_URL;
    if(process.env.node !== "production"){
        base = base.substr(0)
    }
    // return base+path
    return path
}

export const currencyFormat = (val) => {
    return <CurrencyFormat decimalScale={2} value={val} displayType={'text'} thousandSeparator={true} prefix={'$'} />
}