import {FiLogOut} from "react-icons/fi";
import {MdAudiotrack} from "react-icons/md";
import {BiUserCircle} from "react-icons/bi";
import {BiSupport, BiLinkExternal} from 'react-icons/bi'
import {AiOutlineHome, AiOutlineDollarCircle} from "react-icons/ai";
import { BiStats } from "react-icons/bi";
import { FormattedMessage } from "react-intl";



export const userLinks = [
    {
        heading: "Info",
        links: [
            {
                id: 1,
                title: <FormattedMessage id="home" defaultMessage={"Home"}/>,
                to:"/home",
                icon:<AiOutlineHome />
            },
            {
                id: 2,
                title: <FormattedMessage id="account" defaultMessage={"Account"}/>,
                to:"/account",
                icon:<BiUserCircle />
            },
            {
                id: 3,
                title: <FormattedMessage id="tracks" defaultMessage={"Tracks"}/>,
                to:"/tracks",
                icon:<MdAudiotrack />
            },
            // {
            //     id: 4,
            //     title: "Upload Track",
            //     to:"/add-track",
            //     icon:<MdAudiotrack />
            // },
            {
                id: 5,
                title: <FormattedMessage id="payment" defaultMessage={"Payment"}/>,
                to:"/payment",
                icon:<AiOutlineDollarCircle />
            },
            {
                id: 6,
                title: <FormattedMessage id="data.ana" defaultMessage={"Data & Analytics"}/>,
                to:"/statistics",
                icon:<BiStats />
            },
            {
                id: 7,
                title: <FormattedMessage id="support" defaultMessage={"Support"}/>,
                to:"/support",
                icon:<BiSupport />
            },
            {
                id: 10,
                title: <FormattedMessage id="distribution" defaultMessage={"Distribution"}/>,
                to:"https://dashboard.cashgoldrecords.group",
                external: true,
                icon:<BiLinkExternal />
            },
            {
                id: 8,
                title: <FormattedMessage id="logout" defaultMessage={"Logout"}/>,
                to:"/logout",
                logout: true,
                icon:<FiLogOut />
            },
            
        ]
    }
    
]