import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import AuthRoutes from './AuthRoutes'
import AuthenticatedRoutes from './AuthenticatedRoutes'
import { ENGLISH_LANG, INDONESIAN_LANG, THEME_DARK, THEME_LIGHT } from '../constants'
//multilingual imports
import { IntlProvider } from "react-intl";
import en from "../languages/en.json";
import id from "../languages/id.json";
//multilingual imports

//global popup
import BasePopup, { BasePopupContext } from '../components/BasePopup';
//global popup

const   BaseRoutes = () => {
  const user = useSelector(state => state.user);
  const lang = useSelector(state => state.lang)
  const theme = useSelector(state => state.ui.theme);
  // state for popup
  const initialPopupState = {
    component: null,
    visible: false,
    size: "small",
    heading: "",
    contained: false,
    hideDefaultClose: false
  }
  const [popupState, setPopupState] = useState(initialPopupState);

  const openPopup = (params) => setPopupState(old => ({ ...old, ...params }));

  const global_popup_context_data = {
    popupState,
    setPopup: openPopup
  }
  // state for popup

  useEffect(() => {
    const el = document.querySelector("html")
    el.setAttribute('data-theme', theme === THEME_DARK ? THEME_DARK : theme === THEME_LIGHT ? THEME_LIGHT : THEME_LIGHT);
  }, [])
  
  useEffect(() => {
    const el = document.querySelector("html")
    el.dir = lang.ltr ? "ltr" : "rtl"
  }, [lang])

  return (
    <IntlProvider messages={
      lang.lang === ENGLISH_LANG.lang ?
      en
      : lang.lang === INDONESIAN_LANG.lang ?
      id
      :
      en
    } locale="en" defaultLocale="en">
        <BasePopupContext.Provider value={global_popup_context_data}>

            <BasePopup />

    {
      user ?
        <AuthenticatedRoutes/>
      :
        <AuthRoutes/>

    }
        </BasePopupContext.Provider>

    </IntlProvider>
  )
}

export default BaseRoutes