import { configureStore } from "@reduxjs/toolkit";

//reducers below
import userReducer from "./slices/userSlice";
import uiReducer from "./slices/uiSlice";
import langReducer from "./slices/langSlice";
//reducers above

export default configureStore({
    reducer:{
        user: userReducer,
        ui: uiReducer,
        lang: langReducer
    } 
})
