import React from 'react'
import {Routes, Route, Navigate} from "react-router-dom";

// pages
const Home = React.lazy(() => import("../pages/User/Home"));
const Account = React.lazy(() => import("../pages/User/Account"));
const Tracks = React.lazy(() => import("../pages/User/Tracks"));
// const UploadTrack = React.lazy(() => import("../pages/User/UploadTrack"));
const Support = React.lazy(() => import("../pages/User/Support/Listing"));
const SupportDetail = React.lazy(() => import("../pages/User/Support/Detail"));
const Payment = React.lazy(() => import("../pages/User/Payment"));
const Statistics = React.lazy(() => import("../pages/User/Statistics"));
// pages

const UserRoutes = () => {
  return (
        <Routes>
        <Route path="/home" element={<Home />} />
        <Route path="/account" element={<Account />} />
        <Route path="/tracks" element={<Tracks />} />
        {/* <Route path="/add-track" element={<UploadTrack />} /> */}
        <Route path="/support" element={<Support />} />
        <Route path="/support/:id" element={<SupportDetail />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/statistics" element={<Statistics/>} />
        <Route path="*" element={<Navigate to="/home" />} />
        </Routes>
  )
}

export default UserRoutes