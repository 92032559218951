import React from 'react'
import {Routes, Route, Navigate} from "react-router-dom";

// pages
const Users = React.lazy(() => import("../pages/Admin/Users"));
const UserDetail = React.lazy(() => import("../pages/Admin/UserDetail"));
const Support = React.lazy(() => import("../pages/Admin/Support/Listing"));
const SupportDetail = React.lazy(() => import("../pages/User/Support/Detail"));
const Account = React.lazy(() => import("../pages/User/Account"));
const ArtistRevenue = React.lazy(() => import("../pages/Admin/ArtistRevenue"));
const ArtistRevenueListing = React.lazy(() => import("../pages/Admin/ArtistRevenueListing"));
const StreamingPerformance = React.lazy(() => import("../pages/Admin/StreamingPerformance"));
const StreamingPerformanceListing = React.lazy(() => import("../pages/Admin/StreamingPerformanceListing"));
// pages

const AdminRoutes = () => {
  return (
          <Routes>
            <Route path="/users" element={<Users />} />
            <Route path="/users/:id" element={<UserDetail />} />
            <Route path="/support" element={<Support />} />
            <Route path="/support/:id" element={<SupportDetail />} />
            <Route path="/account" element={<Account  />} />
            <Route path="/artist-revenue" element={<ArtistRevenue  />} />
            <Route path="/artist-revenue/:id" element={<ArtistRevenueListing  />} />
            <Route path="/streaming-performance" element={<StreamingPerformance  />} />
            <Route path="/streaming-performance/:id" element={<StreamingPerformanceListing  />} />
            <Route path="*" element={<Navigate to="/users" />} />
          </Routes>
  )
}

export default AdminRoutes