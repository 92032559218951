import React, { Suspense } from 'react'
import {FullScreenLoading} from "../components/Loading";
import { Routes, Route, Navigate } from 'react-router-dom';
// pages
const Login = React.lazy(() => import("../pages/Auth/Login"));
const ForgotPassword = React.lazy(() => import("../pages/Auth/ForgotPassword"));
const SetPassword = React.lazy(() => import("../pages/Auth/SetPassword"));
const ResetPassword = React.lazy(() => import("../pages/Auth/ResetPassword"));
// pages

const AuthRoutes = () => {
  return (
    <Suspense fallback={<FullScreenLoading/>}>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/set-password/:token" element={<SetPassword />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
    </Suspense>
  )
}

export default AuthRoutes