import React, { useState } from 'react';
import { logout } from '../../redux/slices/userSlice';
import { removeToken, success_alert } from '../../utils';
import { BiMusic } from "react-icons/bi";

import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { set_sidebar } from '../../redux/slices/uiSlice';
import { useIntl } from 'react-intl';

const Sidebar = ({ links, }) => {
    const dispatch = useDispatch()
    const intl = useIntl();
    const { pathname } = useLocation();
    const handleLogout = () => {
        removeToken();
        dispatch(logout());
        success_alert(intl.formatMessage({id:"logout.s", defaultMessage:"You were logged out sucessfully!"}))
    }
    const sidebar = useSelector(state => state.ui.sidebar)

    return (
        <>
            <div className={`transition-all z-[100] fixed top-0 bottom-0 w-adjust-container bg-base-100 overflow-y-auto transform rtl:lg:translate-x-0 ltr:lg:translate-x-0 ${sidebar ? "translate-x-0" : "ltr:-translate-x-full rtl:translate-x-full"}`}>
                {/* logo */}
                <div className='my-5 px-4'>
                    {/* <p className="flex items-center space-x-1">
                        <BiMusic className='text-primary text-5xl' />
                        <span className="text-sm font-bold text-primary">Music management</span>
                    </p> */}
                    <img src="/logo.png" className='w-2/4 mx-auto -mb-6' alt="" />
                </div>
                {/* logo */}

                    {links.map((container, index) => (
                        <>
                            {container.heading &&
                                <span key={index} className="mx-4 mt-8 block text-sm font-medium uppercase text-base-content">
                                    {container.heading}
                                </span>}

                            <div className="space-y-3 my-4 mx-6">
                                {container.links.map((link, i) => (

                                    !link.logout && !link.external ?
                                        <Link key={i.id} to={link.to}  className={`${pathname === link.to ? "bg-primary py-3 text-opacity-100 text-white":"text-opacity-70"} text-base-content py-3 transition-all flex  cursor-pointer items-center hover:text-opacity-100  rounded-md  px-3  w-auto`}>
                                            <div className='flex space-x-2 '>
                                                <span className={`${pathname===link.to? "":""} hover:text-white "ltr:pr-3 rtl:pl-3 text-base `}>{link.icon}</span>
                                                <p className="text-[11px] font-medium">{link.title}</p>
                                            </div>
                                        </Link>
                                    :!link.logout && link.external ?
                                        <a key={i.id} href={link.to} target="_blank"  className={`${pathname === link.to ? "bg-primary py-3 text-opacity-100 text-white":"text-opacity-70"} text-base-content py-3 transition-all flex  cursor-pointer items-center hover:text-opacity-100  rounded-md  px-3  w-auto`}>
                                            <div className='flex space-x-2 '>
                                                <span className={`${pathname===link.to? "":""} hover:text-white "ltr:pr-3 rtl:pl-3 text-base `}>{link.icon}</span>
                                                <p className="text-[11px] font-medium">{link.title}</p>
                                            </div>
                                        </a>
                                        :
                                        <p key={i.id}
                                            onClick={handleLogout}
                                            className={` transition-all flex cursor-pointer items-center text-base-content text-opacity-70 hover:text-opacity-100 space-x-2 rounded-md py-2 px-3 w-auto`}>
                                            <span className="pr-2 text-base">{link.icon}</span>
                                            <p className="text-[11px] font-medium">{link.title}</p>
                                        </p>
                                ))}
                            </div>
                        </>
                    ))}
            </div>
            {
                sidebar &&
                <div onClick={() => dispatch(set_sidebar(false))} className="lg:hidden z-[99] fixed bg-black bg-opacity-50 top-0 bottom-0 left-0 right-0">
                    
                </div>
            }
        </>
    )
}

export default Sidebar
