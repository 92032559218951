import {FiLogOut} from "react-icons/fi";
import {MdAttachMoney} from "react-icons/md";
import {BiUserCircle, BiSupport} from "react-icons/bi";
import {FiUsers} from 'react-icons/fi'
import { FormattedMessage } from "react-intl";



export const adminLinks = [
    {
        heading: "Info",
        links: [
            {
                id: 8,
                title: <FormattedMessage id="users" defaultMessage={"Users"}/>,
                to:"/users",
                icon:<FiUsers />
            },
            {
                id: 12,
                title: <FormattedMessage id="artist.revenue" defaultMessage={"Artist Revenue"}/>,
                to:"/artist-revenue",
                icon:<MdAttachMoney />
            },
            {
                id: 13,
                title: <FormattedMessage id="streams_performance" defaultMessage={"Streams Performance"}/>,
                to:"/streaming-performance",
                icon:<MdAttachMoney />
            },
            
            {
                id: 190,
                title: <FormattedMessage id="account" defaultMessage={"Account"}/>,
                to:"/account",
                icon:<BiUserCircle />
            },
            {
                id: 10,
                title: <FormattedMessage id="support" defaultMessage={"Support"}/>,
                to:"/support",
                icon:<BiSupport />
            },
            {
                id: 11,
                title: <FormattedMessage id="logout" defaultMessage={"Logout"}/>,
                to:"/logout",
                logout: true,
                icon:<FiLogOut />
            },
            
        ]
    }
    
]