import React from 'react'
//redux imports
import { Provider } from "react-redux";
import store from "./redux/store";
//redux imports

// notifications alerts
import 'react-notifications-component/dist/theme.css'
import ReactNotification from 'react-notifications-component'
import 'animate.css/animate.min.css';
// notifications alerts

//router
import { BrowserRouter } from "react-router-dom";
import BaseRoutes from "./routes";
//router

const App = () => {


  return (
    <>
      <Provider store={store}>

          <BrowserRouter>
            <ReactNotification />
            <BaseRoutes />
          </BrowserRouter>
      </Provider>
    </>
  )
}

export default App