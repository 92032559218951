import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    ltr: true,
    lang: 'en',
    label: "English"
}

const langSlice = createSlice({
    initialState,
    name: 'lang',
    reducers: {
        set_lang: (state, action) => {
            return action.payload
        }
    }
})

export default langSlice.reducer;

export const {
    set_lang
} = langSlice.actions